import React from "react";

function App() {
  return (
    <div class="wrapper">
      <div class="infobox">
        <div class="info">
          <div class="subtitle">
            <span>Supply Grand Masters</span>
          </div>

          <div class="title">
            <div class="lantern">
              <div class="hook"></div>
              <div class="circle">
                <div class="glow"></div>
              </div>
            </div>

            <h1>Dantra</h1>

            <div class="lantern">
              <div class="hook"></div>
              <div class="circle">
                <div class="glow"></div>
              </div>
            </div>
          </div>

          <div class="subtitle">
            <span>Limited</span>
          </div>

          <div class="links">
            <h1>Experienced and Reliable FMCG Distributors</h1>
            <br />
            <br />
            <h1>
              Connecting wide range of brands from manufacturers to wholesalers
              and retailers in Kenya
            </h1>
            <br />
            <br />
            <a href="tel:+254715932897">Call Us:+254 715 932 897</a>
            <br />
            <br />
            <a
              href="https://api.whatsapp.com/send?phone=254720146788"
              target="_blank"
              rel="noopener noreferrer"
            >
              Send WhatsApp Message
            </a>
            <br />
            <br />
            <a
              href="mailto:admin@dantralimited.com"
              target="_blank"
              rel="noopener noreferrer"
            >
              admin@dantralimited.com
            </a>
            <br />
            <br />
            <br />
            <br />
            <h1>Location: Kasarani, Nairobi</h1>
          </div>
        </div>
      </div>
    </div>
  );
}

export default App;
